import { cloneDeep } from '../../utils';
import type { IPart, IPartNumber } from '../models';

export const getValidListItemsParts = (list: IPart[]): IPart[] => {
  const keys = Object.keys(list[0]);
  const key1 = keys[1];
  const key2 = keys[2];

  let updatedList = removeIncompleteListItemsParts(list, key1, key2);
  updatedList = removeDuplicateListItemsParts(updatedList, key1, key2);
  return updatedList.length > 0 ? updatedList : [];
};

const removeIncompleteListItemsParts = (list: IPart[], key1: string, key2: string): IPart[] => {
  if (key2 === 'partNumbers') {
    return list.filter(item => hasPartOrPartNumber(item, key1, key2));
  } else if (key2 === 'lastProdDateAffected') {
    return list.filter(item => hasFirstEntry(item, key1));
  } else {
    return list.filter(item => hasBothEntries(item, key1, key2));
  }
};

const removeDuplicateListItemsParts = (list: IPart[], key1: string, key2: string): IPart[] => {
  if (key2 === 'partNumbers') {
    const uniqueTableData: IPart[] = [];
    list.forEach((row: IPart) => {
      const existingRow = uniqueTableData.find(uRow => uRow.name.toUpperCase() === row.name.toUpperCase());
      if (existingRow) {
        existingRow.partNumbers.push(...row.partNumbers);
        existingRow.partNumbers = uniqBy(existingRow.partNumbers, (item: IPartNumber) => item.number.toUpperCase());
      } else {
        uniqueTableData.push(row);
      }
    });
    return uniqueTableData;
  } else {
    const orderedList: IPart[] = cloneDeep(list).sort((a: IPart, b: IPart) => a.id - b.id);
    return uniqBy(orderedList, (item: IPart) => (item[key1] + '|' + item[key2]).toUpperCase());
  }
};

const hasFirstEntry = (listItem: IPart, key1: string): boolean => !!listItem[key1];

const hasPartOrPartNumber = (listItem: IPart, key1: string, key2: string): boolean =>
  !!listItem[key1] || listItem[key2].length > 0;

const hasBothEntries = (listItem: IPart, key1: string, key2: string): boolean => !!listItem[key1] && !!listItem[key2];

const uniqBy = <T>(array: T[], iteratee: (value: T) => string): T[] => {
  const result: T[] = [];
  const map = new Map();

  for (const item of array) {
    const mapKey = iteratee(item);
    if (!map.has(mapKey)) {
      map.set(mapKey, true);
      result.push(item);
    }
  }

  return result;
};
