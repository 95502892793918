<app-notification-icon
  *ngIf="showIcon"
  [type]="notification.type"
  class="rc2-pad--right--xxs rc2-pad--top--0 rc2-pad--bottom--0 rc2-pad--left--4xs rc2-display--flex icon-wrapper"
  [ngClass]="{ 'icon-wrapper--multiline': notification?.title && notification?.message }"
  [size]="notification?.title && notification?.message ? 'medium' : 'small'"
  data-cy="notification-icon"
></app-notification-icon>
<ng-container *ngIf="customTemplate; else defaultTemplate">
  <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: notification }"> </ng-container>
</ng-container>
<div
  *ngIf="isClosable"
  class="close-icon rc2-pad--top--0 rc2-pad--right--0 rc2-pad--bottom--0 rc2-pad--left--xs"
  data-cy="notification-close"
>
  <recall2-svg-icon
    data-cy="notification-close-button"
    (click)="onClose($event)"
    name="close"
    size="small"
  ></recall2-svg-icon>
</div>

<ng-template #defaultTemplate>
  <div class="rc2-font--body--m">
    <div data-cy="notification-title" class="rc2-font--bold rc2-mar--bottom--3xs" *ngIf="notification.title">
      {{ notification.title }}
    </div>
    <div class="message" data-cy="notification-message" *ngIf="notification.message">{{ notification.message }}</div>
    <div class="message" data-cy="notification-message" *ngIf="notification.translationKey">
      {{ notification.translationKey | translate }}
    </div>
  </div>
</ng-template>
