import type { EnvironmentProviders } from '@angular/core';
import { APP_INITIALIZER, makeEnvironmentProviders } from '@angular/core';

import { Recall2NewRelicService } from '../new-relic/services/recall2-new-relic.service';
import { recall2FeatureFlagInitializer } from './initializer';
import { RECALL2_FEATURE_FLAG_ENABLED } from './injection-tokens/enabled.token';
import { RECALL2_FEATURE_FLAG_IS_DEV } from './injection-tokens/environment.token';
import { RECALL2_FEATURE_FLAG_FEATURE_PHASES_TOKEN } from './injection-tokens/features-phases.token';
import type { FeaturePhase } from './models/feature-phase.model';
import { Recall2FeatureFlagService } from './services/feature-flag.service';

export type ProvideRecall2FeatureFlag = {
  enabled: boolean;
  featuresPhases?: FeaturePhase[];
  isDevelopmentEnv: boolean;
};

export function provideRecall2FeatureFlag({
  enabled,
  featuresPhases = [],
  isDevelopmentEnv,
}: ProvideRecall2FeatureFlag): EnvironmentProviders[] {
  const providers: EnvironmentProviders[] = [
    makeEnvironmentProviders([
      {
        provide: RECALL2_FEATURE_FLAG_ENABLED,
        useValue: enabled,
      },
      {
        provide: RECALL2_FEATURE_FLAG_FEATURE_PHASES_TOKEN,
        useValue: featuresPhases,
      },
      {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: recall2FeatureFlagInitializer,
        deps: [Recall2FeatureFlagService, Recall2NewRelicService],
      },
      { provide: RECALL2_FEATURE_FLAG_IS_DEV, useValue: isDevelopmentEnv },
    ]),
  ];

  return providers;
}
