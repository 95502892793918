import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { cloneDeep } from '@recall2/ui/utils';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type { INotification } from '../../../models/notification/notification';
import { SummaryComponent } from '../../summary/summary.component';
import { DataService } from '../service/data/data.service';

@Component({
  selector: 'app-notification-summary',
  templateUrl: './notification-summary.component.html',
  standalone: true,
  imports: [SummaryComponent],
})
export class NotificationSummaryComponent implements OnInit, OnDestroy {
  currentNotification$: Observable<INotification> = this.formData.currentNotification$;
  currentNotification: INotification;
  private destroyed$ = new Subject<void>();

  constructor(private formData: DataService) {}

  ngOnInit(): void {
    this.currentNotification$.pipe(takeUntil(this.destroyed$)).subscribe((currentNotification: INotification) => {
      this.currentNotification = cloneDeep(currentNotification);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
