import { Injectable } from '@angular/core';
import { AttachmentService } from '@recall2/ui/attachment';
import { getValidListItemsParts } from '@recall2/ui/core/utils';
import { cloneDeep, compareObjects } from '@recall2/ui/utils';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, share, take } from 'rxjs/operators';

import { NotificationService } from '../../../../../rest-api/notification/notification.service';
import type { INotification } from '../../../../models/notification/notification';
import type { NotificationStatus } from '../../../../models/notification/notification-status.enum';
import { initialCurrentNotificationState } from '../../../../store/notification.state';
import type { InvolvedUserPreliminary } from './../../../../../rest-api/involved-user/involved-user.model';
import { UserService } from './../../../../../rest-api/user/user.service';
import type { UserData } from './../../../../../user/store/user.state';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private notificationSource = new BehaviorSubject<INotification>(null);
  public currentNotification$ = this.notificationSource.pipe(filter(v => !!v));

  private persistedNotification: INotification;

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private attachmentService: AttachmentService,
  ) {}

  public initializeNewNotification(): void {
    this.userService
      .getCurrentUser$()
      .pipe(
        filter(response => !!response),
        take(1),
      )
      .subscribe((received: UserData) => {
        const userAsInvolved: InvolvedUserPreliminary = { ...received, role: 'REPORTER' };

        const initializedNotification = cloneDeep(initialCurrentNotificationState);
        initializedNotification.involvedUsers = [userAsInvolved];

        this.updateCurrentNotification(initializedNotification);
        this.attachmentService.selectObject();

        this.persistedNotification = initializedNotification;
      });
  }

  public fetchNotification(notificationId: number): void {
    this.notificationService
      .getNotificationForId(notificationId)
      .pipe(
        filter(response => !!response),
        take(1),
      )
      .subscribe((received: INotification) => {
        this.updateCurrentNotification(received);
        this.persistedNotification = received;
      });
  }

  public hasChanges(): boolean {
    return (
      !compareObjects(this.notificationSource.getValue(), this.persistedNotification) ||
      this.attachmentService.hasTemporaryAttachmentFiles()
    );
  }

  public updateCurrentNotification(value: INotification): void {
    this.notificationSource.next(cloneDeep(value));
  }

  public saveCurrentNotification(notification: INotification): Observable<INotification> {
    const trimmedNotification = this.removeInvalidNotificationListItems(notification);

    return notification.id ? this.updateNotification(trimmedNotification) : this.saveNotification(trimmedNotification);
  }

  public deleteCurrentNotification(notificationId: number): Observable<boolean> {
    return this.notificationService.deleteCurrentNotification(notificationId).pipe(share());
  }

  public reactivateCurrentNotification(notificationId: number): Observable<NotificationStatus> {
    return this.notificationService.reactivateNotification(notificationId).pipe(share());
  }

  private updateNotification(notification: INotification): Observable<INotification> {
    const updateProcess = this.notificationService.updateNotification(notification.id, notification).pipe(share());

    updateProcess.subscribe(updatedNotification => {
      this.updatePersisted(updatedNotification);
    });

    return updateProcess;
  }

  private saveNotification(notification: INotification): Observable<INotification> {
    const saveProcess = this.notificationService.saveNotification(notification).pipe(share());
    saveProcess.subscribe(savedNotification => {
      this.updatePersisted(savedNotification);
      this.attachmentService.updateObjectIdAfterSave(savedNotification.id);
      this.attachmentService.save();
    });
    return saveProcess;
  }

  private removeInvalidNotificationListItems(notification: INotification): INotification {
    const updatedNotification = notification;
    if (updatedNotification.parts && updatedNotification.parts.length > 0) {
      updatedNotification.parts = getValidListItemsParts(updatedNotification.parts);
    }
    if (updatedNotification.prodDateRangesAffected && updatedNotification.prodDateRangesAffected.length > 0) {
      updatedNotification.prodDateRangesAffected = getValidListItemsParts(updatedNotification.prodDateRangesAffected);
    }
    if (updatedNotification.vehicleRange && updatedNotification.vehicleRange.length > 0) {
      updatedNotification.vehicleRange = getValidListItemsParts(updatedNotification.vehicleRange);
    }
    return updatedNotification;
  }

  private updatePersisted(notification: INotification): void {
    this.persistedNotification = notification;
    this.updateCurrentNotification(notification);
  }

  public loadAttachments(notificationId: number): void {
    this.attachmentService.selectObject(notificationId);
  }

  get _persistedNotification(): INotification {
    return this.persistedNotification;
  }
}
