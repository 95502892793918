/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { BrandsId } from '../../brand-manufacturer/models/brands-id.model';
import type { Manufacturer } from '../../brand-manufacturer/models/manufacturer';
import { findBrandIdByManufacturerName } from '../../brand-manufacturer/utils/find-brand-id-by-manufacturer-name.util';
import type { IRecall2FilterSelectableValue } from '../../overlay/models/filter.model';
import { Recall2FilterSelectableValue } from '../../overlay/models/filter.model';

export function createManufacturerSelectableValues(
  manufacturerList: Manufacturer[],
  manufacturerSelectableValues: IRecall2FilterSelectableValue[],
  valuePropertyName?: string,
): void {
  if (manufacturerList) {
    manufacturerSelectableValues.splice(0, manufacturerSelectableValues.length);
    for (const manufacturer of manufacturerList) {
      const value = valuePropertyName ? manufacturer[valuePropertyName] : manufacturer.name;
      let iconClass = 'rc2-icon--size--24 rc2-pad--right--xs ';
      const name = manufacturer.name ? manufacturer.name.toUpperCase() : '';
      const brandId: BrandsId = findBrandIdByManufacturerName(name);
      switch (brandId) {
        case BrandsId.VOLKSWAGEN_PKW:
        case BrandsId.VOLKSWAGEN_NUTZFAHRZEUGE:
          iconClass += 'icon-i34-logo-vw';
          break;
        case BrandsId.AUDI:
          iconClass += 'icon-i35-logo-audi';
          break;
        case BrandsId.SEAT:
          iconClass += 'icon-i36-logo-seat';
          break;
        case BrandsId.SKODA:
          iconClass += 'icon-i37-logo-skoda';
          break;
        case BrandsId.BENTLEY:
          iconClass += 'icon-i38-logo-bentley';
          break;
        case BrandsId.MAN:
          iconClass += 'icon-i39-logo-man';
          break;
        case BrandsId.PORSCHE:
          iconClass += 'icon-i40-logo-porsche';
          break;
        case BrandsId.LAMBORGHINI:
          iconClass += 'icon-i113-logo-lamborghini';
          break;
        case BrandsId.BUGATTI:
          iconClass += 'icon-i112-logo-bugatti';
          break;
        case BrandsId.SCANIA:
          iconClass += 'icon-i115-logo-scania';
          break;
        case BrandsId.DUCATI:
          iconClass += 'icon-i114-logo-ducati';
          break;
        default:
          iconClass = '';
          break;
      }

      manufacturerSelectableValues.push(new Recall2FilterSelectableValue(iconClass, value, manufacturer.name));
    }
  }
}

// CREDITS: https://github.com/joelwass/js-deep-equals
// eslint-disable-next-line @typescript-eslint/ban-types
export function compareObjects<T, K>(arr1: Array<T> | object, arr2: Array<K> | object): boolean {
  if (!arr1 || !arr2) {
    return false;
  }

  if (
    Array.isArray(arr1) &&
    Array.isArray(arr2) && // a arr1 array does not look like a arr2 array
    arr1.length !== arr2.length
  ) {
    return false;
  }

  const keys = Object.keys(arr1);

  const keys2 = Object.keys(arr2);

  if (
    typeof arr1 === 'object' &&
    typeof arr2 === 'object' &&
    !Array.isArray(arr1) &&
    !Array.isArray(arr2) &&
    keys.length !== keys2.length
  ) {
    return false;
  }

  for (const key of keys) {
    if (Array.isArray(arr1[key])) {
      if (Array.isArray(arr2[key])) {
        // a arr1 has an array, a arr2 has a different array
        if (!compareObjects(arr1[key], arr2[key])) {
          return false;
        }
        // a arr1 may share an array with a arr2
        continue;
      }
      // a arr1 has an array, a arr2 does not
      return false;
    }

    // account for date objects
    if (arr1[key] instanceof Date) {
      if (arr2[key] instanceof Date) {
        if (arr1[key].valueOf() !== arr2[key].valueOf()) {
          return false;
        }
        continue;
      }
      return false;
    }

    // account for regexp
    if (arr1[key] instanceof RegExp) {
      if (arr2[key] instanceof RegExp) {
        if (arr1[key].toString() !== arr2[key].toString()) {
          return false;
        }
        continue;
      }
      return false;
    }

    if (typeof arr1[key] === 'object' && arr1[key] !== null) {
      if (typeof arr2[key] === 'object' && !Array.isArray(arr2[key])) {
        // a arr1 has an object, a arr2 has a different object
        if (!compareObjects(arr1[key], arr2[key])) {
          return false;
        }

        continue;
      }
      // a arr1 has an object, a arr2 does not
      return false;
    }

    // a arr1 has values that a arr2 does not share
    if (arr1[key] !== arr2[key]) {
      return false;
    }
  }

  return true;
}

// CREDITS: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
export function getValueFromObject<T, K>(obj: T, path: string, defaultValue?: K) {
  const travel = (regexp: RegExp) => {
    return String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res: unknown, key: string) => (res !== null && res !== undefined ? res[key] : res), obj);
  };

  const result = travel(/[,[\]]+?/) || travel(/[,.[\]]+?/);
  return result === undefined || result === obj ? defaultValue : result;
}

// CREDITS: https://gist.github.com/bitfishxyz/b5470d59b25c6919abe6b57f6b9e57ac#file-clone-js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cloneDeep(target: any, map = new WeakMap()) {
  // clone primitive types
  // eslint-disable-next-line eqeqeq
  if (typeof target !== 'object' || target == null) {
    // It is meaningful that this is 'target == null' instead of 'target === null'
    return target;
  }

  const type: string = Object.prototype.toString.call(target).slice(8, -1);
  let cloneTarget = null;

  if (map.get(target)) {
    return map.get(target);
  }
  map.set(target, cloneTarget);

  if (type !== 'Set' && type !== 'Map' && type !== 'Array' && type !== 'Object') {
    switch (type) {
      case 'Boolean':
      case 'Number':
      case 'String':
      case 'Error':
      case 'Date':
        return new target.constructor(target);
      case 'RegExp': {
        const reFlags = /\w{0,25}$/;
        const result = new target.constructor(target.source, reFlags.exec(target));
        result.lastIndex = target.lastIndex;
        return result;
      }
      case 'Symbol':
        return new Object(Symbol.prototype.valueOf.call(target));
      case 'Function':
        return target;
      default:
        return null;
    }
  }

  // clone Set
  if (type === 'Set') {
    cloneTarget = new Set();
    for (const value of target) {
      cloneTarget.add(cloneDeep(value, map));
    }
    return cloneTarget;
  }

  // clone Map
  if (type === 'Map') {
    cloneTarget = new Map();
    for (const [key, value] of target.entries()) {
      cloneTarget.set(key, cloneDeep(value, map));
    }
    return cloneTarget;
  }

  // clone Array
  if (type === 'Array') {
    cloneTarget = [];
    for (const [index, value] of target.entries()) {
      cloneTarget[index] = cloneDeep(value, map);
    }
  }

  // clone normal Object
  if (type === 'Object') {
    cloneTarget = new Object();

    for (const key of Object.keys(target)) {
      cloneTarget[key] = cloneDeep(target[key], map);
    }
  }

  return cloneTarget;
}

export function sumBy<T>(array: T[], iteratee: (value: T) => number): number {
  let result: number;

  if (!array || array.length === 0) {
    return result;
  }

  for (const value of array) {
    const current = iteratee(value);

    if (current) {
      result = result === undefined ? current : result + current;
    }
  }

  return result;
}
