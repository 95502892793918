import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AdminPanelRoutingModule, GET_ADMIN_PANEL_USER_ROLES } from '@recall2/ui/admin-panel';
import { ErrorHandlingEffects, HttpErrorResponseInterceptor, IdNotFoundInterceptor } from '@recall2/ui/errorhandling';
import { ExternalRoutingModule } from '@recall2/ui/external-url';
import { FeaturePhase, provideRecall2FeatureFlag } from '@recall2/ui/feature-flag';
import { HttpTranslateLoader } from '@recall2/ui/i18n';
import type { ERole } from '@recall2/ui/navbar';
import { provideNewRelic } from '@recall2/ui/new-relic';
import { UserService } from '@recall2/user';
import type { Observable } from 'rxjs';

import { UserServiceImpl } from '@/rest-api/user-create/user-service-impl.service';

import { AppComponent } from './app/app.component';
import { provideAuth } from './app/core/app-initializer';
import { NotificationModule } from './app/notification/notification.module';
import { RestAPIModule } from './app/rest-api/rest-api.module';
import { RoutingModule } from './app/routing/routing.module';
import { appReducer } from './app/store/app/app.reducer';
import { initialAppState } from './app/store/app/app.state';
import { UserModule } from './app/user/user.module';
import { PermissionService } from './app/utility/permission/permission.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (translateLoader: HttpTranslateLoader): HttpTranslateLoader => translateLoader,
          deps: [HttpTranslateLoader],
        },
        isolate: false,
      }),
      RoutingModule,
      UserModule,
      StoreModule.forRoot(appReducer, {
        initialState: initialAppState,
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
        },
      }),
      EffectsModule.forRoot(ErrorHandlingEffects),
      StoreDevtoolsModule.instrument({
        name: 'vw-recall2-preliminary',
        connectInZone: true,
      }),
      ExternalRoutingModule,
      AdminPanelRoutingModule,
      RestAPIModule.forRoot(),
      NotificationModule,
    ),
    provideAuth(),
    provideNewRelic({ enabled: environment.newRelic.enabled, setting: environment.newRelic.setting }),
    provideRecall2FeatureFlag({
      enabled: true,
      featuresPhases: [FeaturePhase.Shared, FeaturePhase.Preliminary],
      isDevelopmentEnv: environment.featureFlagDevelopment,
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdNotFoundInterceptor,
      multi: true,
    },
    DatePipe,
    {
      provide: GET_ADMIN_PANEL_USER_ROLES,
      useFactory: (permissionService: PermissionService): Observable<ERole[]> => permissionService.getUserRoles(),
      deps: [PermissionService],
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    {
      provide: UserService,
      useExisting: UserServiceImpl,
      deps: [HttpClient],
    },
  ],
}).catch(error => console.error(error));
