<app-toast-container></app-toast-container>

<recall2-header [roles]="roles"></recall2-header>

<recall2-navbar [roles]="roles">
</recall2-navbar>

<div class="main-body">
  <router-outlet></router-outlet>
</div>

<recall2-footer></recall2-footer>
