import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError, combineLatest, first, mergeMap, of, tap } from 'rxjs';

import { Recall2NewRelicService } from '../../new-relic/services/recall2-new-relic.service';
import { RECALL2_FEATURE_FLAG_BASE_API_URL } from '../base-api-url';
import { RECALL2_FEATURE_FLAG_ENABLED } from '../injection-tokens/enabled.token';
import { RECALL2_FEATURE_FLAG_IS_DEV } from '../injection-tokens/environment.token';
import { RECALL2_FEATURE_FLAG_FEATURE_PHASES_TOKEN } from '../injection-tokens/features-phases.token';
import type { FeatureFlag } from '../models/feature-flag.model';
import type { FeaturePhase } from '../models/feature-phase.model';
import type { FeaturePhaseData } from '../models/feature-phase-data.model';

@Injectable({ providedIn: 'root' })
export class Recall2FeatureFlagService {
  private httpClient: HttpClient = inject(HttpClient);
  private enabledToken = inject(RECALL2_FEATURE_FLAG_ENABLED);
  private featurePhasesToken = inject(RECALL2_FEATURE_FLAG_FEATURE_PHASES_TOKEN);
  private isDevelopmentEnv = inject(RECALL2_FEATURE_FLAG_IS_DEV);
  private newRelicService = inject(Recall2NewRelicService);

  private featurePhases = new Map<FeaturePhase, FeaturePhaseData>();
  private isInitialised = false;

  get initialised(): boolean {
    return this.isInitialised;
  }

  initialise(): Observable<void> {
    if (this.initialised || !this.enabledToken || this.featurePhasesToken.length === 0 || this.isDevelopmentEnv) {
      return of();
    }

    this.featurePhases = new Map();

    return combineLatest(
      this.featurePhasesToken.map(featurePhase =>
        this.httpClient.get<FeaturePhaseData>(`${RECALL2_FEATURE_FLAG_BASE_API_URL}/${featurePhase}/profiles`).pipe(
          catchError(() => of({})),
          first(),
          tap(data => {
            this.featurePhases.set(featurePhase, data);
          }),
        ),
      ),
    ).pipe(
      first(),
      tap(() => {
        this.isInitialised = true;
      }),
      mergeMap(() => of()),
    );
  }

  isActive({ feature, featurePhase }: FeatureFlag): boolean {
    if (this.isDevelopmentEnv) {
      return true;
    }

    if (!this.initialised) {
      return false;
    }

    const features: FeaturePhaseData = this.featurePhases.get(featurePhase);

    if (!features) {
      this.newRelicService.noticeError(
        new Error(
          `The feature phase "${featurePhase}" has not been loaded. Try to initialise the module with this phase.`,
        ),
      );

      return false;
    }

    if (typeof features[feature] === 'undefined') {
      this.newRelicService.noticeError(
        new Error(`The feature "${feature}" does not exist in the feature phase "${featurePhase}".`),
      );

      return false;
    }

    return features[feature];
  }
}
